import React, { useState, useRef, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import colors from "src/assets/styles/colors.js";
import Seo from "src/components/global/Seo.js";
import slugify from "slugify";
import Img from "gatsby-image";
import { Link } from "gatsby";

import Nav from "src/components/global/Nav.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import distances from "src/assets/styles/distances.js";
import Project from "src/components/global/Projects/Project.js";
import ProjectGrid from "src/components/global/Projects/ProjectGrid.js";

import Title from "src/components/global/Typo/BigTitle.js";
import BigParagraph from "src/components/global/Typo/BigParagraph.js";
import Body from "src/components/global/Typo/Body.js";
import Line from "src/components/global/Line.js";
import listview from "src/assets/svg/view_list.svg";
import gridview from "src/assets/svg/grid_view.svg";
import main from "src/assets/styles/main.js";

import MainWrapper from "src/components/global/MainWrapper.js";
import typographySizes from "../assets/styles/typographySizes";
import Button from "src/components/global/Button.js";
import Highlighted from "src/components/works/Highlighted.js";
import Animate from "src/components/global/Typo/Animate.js";
import AnimateBody from "src/components/global/Typo/AnimateBody.js";
import Footer from "src/components/global/Footer.js";
// markup

const Wrapper = styled.div`
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 15rem;
  }
`;

const StyledTitle = styled(Title)`
  position: relative;
  left: -1vw;
`;
const TopWrapper = styled.div`
  padding-top: ${distances.topMargin}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: ${distances.topMarginMobile}rem;
  }
`;

const StyledBigParagraph = styled(BigParagraph)`
  width: 85.71%;
  margin-top: 50rem;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 20rem;
  }
`;

const ProductsContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-bottom: ${distances.sectionMarginBig}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;

const TitleProducts = styled.h2`
  font-size: ${typographySizes.s}rem;
  color: var(--rest);
  margin-bottom: 15rem;
  opacity: 1;
`;

const Position = styled(Body)`
  margin: ${distances.gap}rem 0;

  /* @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  } */
  /* &.mobile {
    display: none;
    @media (max-width: ${mediaQuery.tablet}) {
      display: block;
      margin-top: 0;
    }
  } */
`;
const InWrapper = styled.div`
  overflow: hidden;
`;
const PersonImage = styled(Img)`
  border-radius: ${main.radius}rem;
  position: relative;
  height: 32vw;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.laptop}) {
    height: 60vw;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: 130vw;
  }
`;

const InfoWrapper = styled.div`
  position: relative;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;

const PersonName = styled.h3`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};
  color: var(--rest);
  margin: ${distances.gap}rem 0;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 50%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`;

const Description = styled(Body)`
  position: absolute;
  @media (max-width: ${mediaQuery.tablet}) {
    position: relative;
    margin-bottom: ${distances.gap}rem;
    margin-top: 50rem;
  }
`;

const TopRowProductWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PersonWrapper = styled(Link)`
  width: calc(25% - ${(distances.gap * 3) / 4}rem);
  margin-right: ${distances.gap}rem;
  display: flex;
  flex-direction: column;
  margin-top: ${distances.gap * 3}rem;
  &:nth-child(4n) {
    margin-right: 0rem;
  }
  &:nth-child(-n + 4) {
    margin-top: 0rem;
  }
  .hoverImg {
    display: none;
  }
  :hover {
    ${PersonImage} {
      display: none;
    }
    .hoverImg {
      display: block;
    }
  }
  /* :hover {
    ${InfoWrapper} {
      transform: translateY(-35vw);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    ${PersonImage} {
      transform: translateY(-35vw);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    @media (max-width: ${mediaQuery.laptop}) {
      ${InfoWrapper} {
        transform: translateY(-60vw);
      }
      ${PersonImage} {
        transform: translateY(-60vw);
      }
    }
    @media (max-width: ${mediaQuery.tablet}) {
      ${InfoWrapper} {
        transform: translateY(0);
      }
      ${PersonImage} {
        transform: translateY(0);
      }
    }
  } */
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(50% - ${distances.gap / 2}rem);
    &:nth-child(4n) {
      margin-right: ${distances.gap}rem;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:nth-child(-n + 4) {
      margin-top: ${distances.gap * 3}rem;
    }
    &:nth-child(-n + 2) {
      margin-top: 0;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;

    margin-right: 0;

    &:nth-child(-n + 2) {
      margin-top: ${distances.gap * 3}rem;
    }
    &:first-of-type {
      margin-top: 0;
    }
    /* hr:first-of-type {
      display: none;
    } */
  }
`;

const Shop = ({ data, pageContext }) => {
  return (
    <Wrapper>
      <Seo
        title={data.strapiPageShop.Seo && data.strapiPageShop.Seo.Title}
        description={
          data.strapiPageShop.Seo && data.strapiPageShop.Seo.Description
        }
        image={
          data.strapiPageShop.Seo &&
          data.strapiPageShop.Seo.Img.localFile.publicURL
        }
      />
      <Nav
        contactLink={data.strapiContactLink}
        newsLinkPage={data.strapiPageNews.Page_name}
        lang={pageContext.locale}
        langPageName={pageContext.langPageName}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        shopPage={data.strapiPageShop.Page_name}
      />
      <MainWrapper>
        <TopWrapper>
          <StyledTitle>
            <Animate text={data.strapiPageShop.Title} />
          </StyledTitle>
          <StyledBigParagraph className="intro-text">
            <AnimateBody text={data.strapiPageShop.Description} />
          </StyledBigParagraph>
        </TopWrapper>
        <TitleProducts>{data.strapiPageShop.All_products_title}</TitleProducts>
        <ProductsContainer>
          {data.allStrapiShopProduct.edges.map((pos) => {
            const slugifyedTitle = slugify(pos.node.Title, {
              lower: true,
              strict: true,
            });

            // const url =
            //   pageContext.locale === "pl"
            //     ? "sklepik"
            //     : `${pageContext.locale}/shop`;
            return (
              <PersonWrapper to={slugifyedTitle}>
                <Line />
                <TopRowProductWrapper>
                  <Position>{pos.node.Type_of_product}</Position>
                  <Position>{pos.node.Price} PLN</Position>
                </TopRowProductWrapper>

                <InWrapper>
                  <PersonImage
                    loading="eager"
                    fluid={pos.node.Media[0].localFile.childImageSharp.fluid}
                  />
                  <PersonImage
                    className="hoverImg"
                    loading="eager"
                    fluid={
                      pos.node.Media[pos.node.Media[1] ? 1 : 0].localFile
                        .childImageSharp.fluid
                    }
                  />
                  {/* {pos.node.Media} */}
                  <InfoWrapper>
                    <PersonName>{pos.node.Title} </PersonName>
                    {/* <Position className="mobile">{position}</Position> */}
                    {/* <Description>{info}</Description> */}
                  </InfoWrapper>
                </InWrapper>
                <Line />
              </PersonWrapper>
            );
          })}
        </ProductsContainer>
      </MainWrapper>

      <Footer
        texts={data.strapiFooter}
        contactLink={data.strapiContactLink}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        lang={pageContext.locale}
      />
    </Wrapper>
  );
};

export default Shop;

export const IndexQuery = graphql`
  query ShopPage($locale: String!) {
    strapiPageShop(locale: { eq: $locale }) {
      Page_name
      Description
      Title
      All_products_title
      Seo {
        Title
        Description
        Img {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiShopProduct(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          Price
          Title
          Type_of_product
          Media {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 508
                  quality: 100
                  webpQuality: 100
                  jpegQuality: 100
                  pngQuality: 100
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    strapiGlobal(locale: { eq: $locale }) {
      Project_button
    }

    strapiFooter(locale: { eq: $locale }) {
      Mail_text
      Tel_text
      Top_text
    }

    strapiContactLink(locale: { eq: $locale }) {
      Main_mail
      Main_tel
      Social_link {
        Link
        Name
      }
      Map_link
      City
      Zip_code
      Street
      Map_text
    }
    strapiPageWork(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageAbout(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageNews(locale: { eq: $locale }) {
      Page_name
    }

    strapiPageContact(locale: { eq: $locale }) {
      Page_name
    }
  }
`;
